import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls"
import api from '../api'
var ls = new SecureLS({isCompression: true  , encodingType:'LZString' })
import router from '../router'
import { Date } from 'core-js'

Vue.use(Vuex)
function createSecureLSStorage(key) {
  return {
    getItem: () => ls.get(key),
    setItem: (value) => {
      try {
        ls.set(key, value);
      } catch (error) {
        ls.remove(key)
        console.log('LocalStorage error.')
      }
    },
    removeItem: () => ls.remove(key),
  };
}
export default new Vuex.Store({
  state: {
    all:[{
      childId: 0,
      orderIndex: 0,
      parentId: 0,
      content: {
        content_id: 0,
        title_en: null,
        title_image: null,
        title_la: null,
        subtitle_en: null,
        subtitle_la: null,
        caption: null,
        contact_no: null,
        body_en: null,
        body_la: null,
        body_type: null,
        body_display_style: null,
        content_type: null,
        content_display_style: null,
        has_map: false,
        tag: null,
        lat: 0.0,
        lon: 0.0,
        oldid: null,
        published: false,
        tbl_content_image: [],
      },
    }],
    content:[],
    token:'',
    refreshToken:'',
    lastRefresh: Date.now(),
    uploadPercent:0,
    user:{},
    selectedContent:null,
    selectedCategory:null,
    refreshContent:false,
    isMainItem:false,
    notify:[],
    welcome:[],
    payment:[],
    persons:[],
    users:[],
    data:{
      locations:[],
      departments:[],
      positions:[],
      study:[],
      units:[],
      theory:[],
      jobTitle:[],
      activity:[],
      studyFrom:[],
      districts:[],
      provinces:[],
      religion:[]
    },
  },
  getters: {
  },
  mutations: {
    setProducts (state, products) {
      state.all = products
    },
    setContents (state, content) {
      state.content = content
    },

    addUser(state, content) {
      state.users.push(content)
    },
    setUser(state, content) {
      state.users=content
    },
    
    addPerson(state, content) {
      state.persons.push(content)
    },
    setPerson(state, content) {
      state.persons=content
    },

    addPayment (state, content) {
      state.payment.push(content)
    },
    setPayment (state, content) {
      state.payment=content
    },
    addUnits (state, content) {
      state.data.units.push(content)
    },
    setUnits (state, content) {
      state.data.units=content
    },
    addDepartment (state, content) {
      state.data.departments.push(content)
    },
    setDepartment (state, content) {
      state.data.departments=content
    },
    setWelcomeData (state, content) {
      state.welcome = content
    },
    setAppData (state, content) {
      state.data = content
    },
    setToken(state, data){
      if(data.token && data.token!=''){
        state.token=data.token
        state.refreshToken=data.refreshToken
        state.user=data.user
        state.lastRefresh=Date.now()
        router.replace("/")
      }else{
        state.token={}
        state.user={}
        router.replace("/login")
      }
    },
    updatePercent(state, data){
      state.uploadPercent=data
    },
    setSelectedContent(state, data){
      state.selectedContent=data
    },
    setSelectedCategory(state, data){
      state.selectedCategory=data
    },
    refreshContent(state, data){
      state.refreshContent=data
    },
    setIsmainItem(state, data){
      state.isMainItem=data
    },
    setNotify(state, data){
      state.notify=data
    }
  },
  actions: {
    async getAllProducts ({ commit }) {
      const products = [];//await api.getProducts()
      commit('setProducts', products)
    },
    async reloadContents ({ commit }) {
      const content = await api.getContent()
      commit('setContents', content)
    }, 
    addUser({commit},data){
      commit('addUser', data)
    },
    setUser({commit},data){
      commit('setUser', data)
    },
    addPerson({commit},data){
      commit('addPerson', data)
    },
    setPerson({commit},data){
      commit('setPerson', data)
    },
    addPayment({commit},data){
      commit('setPayment', data)
    },
    setPayment({commit},data){
      commit('setPayment', data)
    },
    addUnits({commit},data){
      commit('addUnits', data)
    },
    setUnits({commit},data){
      commit('setUnits', data)
    },
    addDepartment ({commit},data) {
      commit('addDepartment', data)
    },
    setDepartment ({commit},data) {
      commit('setDepartment', data)
    },
    setAppData ({commit},data) {
      commit('setAppData', data)
    },
    setWelcomeData ({commit},data) {
      commit('setWelcomeData', data)
    },
    setToken({commit},data){
      //console.log(`Dispatch action: ${token}`);
      commit('setToken', data)
    },
    setContents({commit},data){
      //console.log(`Dispatch action: ${token}`);
      commit('setContents', data)
    },
    updatePercent({commit},data){
      commit('updatePercent', data)
    },
    setSelectedContent({commit},data){
      commit('setSelectedContent', data)
    },
    setSelectedCategory({commit},data){
      commit('setSelectedCategory', data)
    } ,
    refreshContent({commit},data){
      commit('refreshContent', data)
    },
    setIsmainItem({commit},data){
      commit('setIsmainItem', data)
    },
    setNotify({commit},data){
      commit('setNotify', data)
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      key:'uzrx01',
      paths: ['users'],
      storage:createSecureLSStorage('uzrx01')
    }),
    createPersistedState({
      key:'uzrx02',
      paths: ['all'],
      storage: createSecureLSStorage('uzrx02')
    }),
    createPersistedState({
      key:'uzrx03',
      paths: ['persons'],
      storage:createSecureLSStorage('uzrx03')
    }),
    createPersistedState({
      key:'uzrx04',
      paths: ['payment'],
      storage: createSecureLSStorage('uzrx04')
    }),
    createPersistedState({
      key:'uzrx05',
      paths: ['content'],
      storage: createSecureLSStorage('uzrx05')
    }),
    createPersistedState({
      key:'uzrx06',
      paths: ['user'],
      storage: createSecureLSStorage('uzrx06')
    }),
    createPersistedState({
      storage: createSecureLSStorage('uzrx00') /*{
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },*/
    }),
  ]
})
