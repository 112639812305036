import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Swal from "sweetalert2";
import '@mdi/font/css/materialdesignicons.css'
import 'buefy/dist/buefy.css'
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import _ from "lodash";
import Axios from "axios" 
import moment from "moment"; 

import ElementUI from 'element-ui';
import { ElementTiptapPlugin } from 'element-tiptap';
import 'element-ui/lib/theme-chalk/index.css'; 
import 'element-tiptap/lib/index.css';

Vue.use(ElementUI); 
Vue.use(ElementTiptapPlugin);
 
Vue.filter('datetime', (x) =>{
  if (x) {
    return moment(x).format("DD/MM/YYYY HH:mm:ss");
  }
  return x;
})

Vue.filter('date',(x) =>{
  if (x) {
    return moment(x).format("DD/MM/YYYY");
  }
  return x;
})

Vue.filter('datemonth',(x) =>{
  if (x) {
    return moment(x).format("MM/YYYY");
  }
  return x;
})

Vue.filter('truncate',(value, length) =>{
  if(value) return value.length > length
      ? value.substr(0, length) + '...'
      : value
    else return value
})

Vue.filter('formatLAK',(x) =>{
  if (!x) x = 0;
    if (typeof x === 'string') {
        x = parseFloat(x);
    }
    const amt = x.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'LAK',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0 
    });
    return `₭ ${amt.split('LAK')[0].trim()}`;
   
})

Vue.use(Buefy);
Vue.config.productionTip = false 
Axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "/api/public" : "http://localhost/moic-labourunion/public";
Axios.interceptors.request.use(
  (config) => {
    let token = store.state.token;
    if (token && token!='') {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject("request", error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    if (response.status) {
      /*if (response.data.token) {
        store.state.token=response.data.token;
      }*/
      if(response.status==200) store.state.lastRefresh=Date.now()
      
      return Promise.resolve(response.data);
    } else Promise.reject(response);
  },
  async(error) =>  {
    
    if (error && error.response && router.currentRoute.name!='login') {
      const message = process.env.NODE_ENV == "production" ? "" : JSON.stringify(error.response.data.message);
      switch (error.response.status) {
        case 401:
          if(store.state.refreshToken!='' && (Date.now()-store.state.lastRefresh)<1800000){
            try{
              await Axios.post(`/token`,{token: store.state.refreshToken})
              .then((res) => {
                //console.log(res);
                store.state.token=res.token;
              }).catch((error) => {
                console.log(error);
              });
            }catch(err){
              console.log(err);
            }
            return Promise.reject(error.response.statusText);
          }else{
            store.state.token='';
            store.state.refreshToken='';
            //Swal.fire(error.response.statusText, message, "warning");
            Swal.fire("Session expire", "Please login to continue", "warning");
            router.replace("/login");
            return Promise.reject(error.response.statusText);
          }
          case 403:
              store.state.token='';
              store.state.refreshToken='';
              Swal.fire("Session expire", "Please login to continue", "warning");
              router.replace("/login");
              return Promise.reject(error.response.statusText);
        default:
          console.log(error)
          Swal.fire(error.response.statusText, message , "warning");
          return Promise.reject(error.response.data ? error.response.data : error.response);
      }
    } else if (error && error.response && router.currentRoute.name=='login'){
      if(error.response.data && error.response.data.message) Swal.fire(error.response.data.message || 'Authentication error','Please check your credential' , "warning");
      else  Swal.fire( 'Authentication error','Can not onnect to server' , "error");
      
      return Promise.reject(error.response.data ? error.response.data : error.response);
    }
    else {
      return Promise.reject(error);
    }
  }
);

Object.defineProperty(Vue.prototype, "_", {
  value: _,
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
