<template>
  <div class="home">
    <div class="content">
      <p class="title">
       ລະບົບບັນທຶກເກັບກຳຂໍ້ມູນ ສະຖິຕິ ກຳມະບານ ກະຊວງອຸດສະຫະກຳ ແລະ ການຄ້າ
       <br />&nbsp;
      </p>
    </div>
    <div class="columns is-desktop">
      <div class="column" v-for="m in (user.roleid=='ADMIN')?menu:menu.filter(e=>e.route!='users')" :key="m.route" >
        <a class="custom-link"  @click="view(m.route)">
          <article class="tile is-child box">
            <p class="title has-text-centered">
              <b-icon :icon="m.icon" size="is-large" type="is-success">{{ m.route }}</b-icon>
            </p>
            <p class="has-text-centered">
              <span class="subtitle">{{ m.label }}</span>
              <br /> &nbsp;
            </p>
            <br /> &nbsp;
          </article>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
//import Axios from "axios";
//import router from "../router";
import { mapState } from "vuex";
export default {
  name: "HomeView",
  data() {
    return {
      isLoading: false,
      isPaginated: true,
      currentPage: 1,
      perPage: 20,
      loadingComponent: null,
      menu:[
        {route:'person',icon:'account-group-outline',label:'ສະມະຊິກ'},
        {route:'payment',icon:'cash-plus',label:'ຊຳລະເງິນສະຖິຕິ'},
        {route:'report',icon:'file-chart-outline',label:'ລາຍງານ'},
        //{route:'unit',icon:'format-list-group',label:'ໜ່ວຍກຳມະບານ'},
        {route:'department',icon:'home-city-outline',label:'ຂົງເຂດ ແລະ ພະແນກການ'},
        {route:'users',icon:'account-key',label:'ຂໍ້ມູນຜູ້ເຂົ້າໃຊ້ລະບົບ'}
      ]
    };
  },
  computed: mapState({
    user: (state) => state.user,
  }),
  watch: {
    isLoading(newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        this.loadingComponent = this.$buefy.loading.open({
          container: null,
        });
      } else {
        this.loadingComponent.close();
      }
    },
  },
  mounted() {
   
  },
  methods: {
    loadContent() {
     
    },
    view(id) {
          this.$router.push(`/${id}`);
    }
  }
};
</script>

<style scoped>
article:hover {
  transform: scale(1.05);
}
article {
  transform: scale(1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
</style>
