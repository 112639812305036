<template>
  <div id="app">
    <b-navbar
    v-if="`${auth}`.length > 30"
      class="navbar"
      role="navigation"
      aria-label="main navigation"
      style="border-bottom: 1px solid"
    >
    <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
            <img src="/moic_logo.png" alt="Home"  >
            &nbsp;<span>&nbsp;ລະບົບບັນທຶກເກັບກຳຂໍ້ມູນ ສະຖິຕິ ກຳມະບານ</span>
        </b-navbar-item>
    </template>
    <template #start>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
            <b-icon icon="format-list-group">Group</b-icon>
            &nbsp;<span>ໜ້າຫຼັກ</span>
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/person' }">
            <b-icon icon="account-group-outline">labour</b-icon>
            &nbsp;<span>ສະມະຊິກ</span>
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/payment' }">
            <b-icon icon="cash-plus">payment</b-icon>
              &nbsp;<span>ເງິນສະຖິຕິ</span>
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/report' }">
            <b-icon icon="file-chart-outline">Group</b-icon>
              &nbsp;<span>ລາຍງານ</span>
        </b-navbar-item>
        <b-navbar-dropdown label="ອື່ນໆ">
            <!--b-navbar-item tag="router-link" :to="{ path: '/unit' }">
              <b-icon icon="format-list-group">Group</b-icon>
              &nbsp;<span>ໜ່ວຍກຳມະບານ</span>
            </b-navbar-item -->
            <b-navbar-item tag="router-link" :to="{ path: '/department' }">
              <b-icon icon="home-city-outline">Office</b-icon>
              &nbsp;<span>ຂົງເຂດ ແລະ ພະແນກການ </span>
            </b-navbar-item>
            <b-navbar-item v-if="user.roleid=='ADMIN'" tag="router-link" :to="{ path: '/users' }"> 
                <b-icon icon="account-key">accounts</b-icon>
                &nbsp;<span>ຂໍ້ມູນຜູ້ໃຊ້ລະບົບ</span>
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/about' }">
                <b-icon icon="information-outline">about</b-icon>
                &nbsp;<span>About</span>
            </b-navbar-item>
        </b-navbar-dropdown>
    </template>

    <template #end>
            <b-navbar-item tag="div">
                <div class="buttons">
                  <b-navbar-item class="button is-primary is-small" title="ປ່ຽນລະຫັດຜ່ານ"  tag="router-link" :to="{ path: '/changepwd' }">
                      <b-icon icon="form-textbox-password">change password</b-icon>
                    </b-navbar-item>
                    <b-navbar-item class="button is-light is-small" title="ອອກຈາກລະບົບ" tag="router-link" :to="{ path: '/logout' }">
                      <b-icon icon="logout">logout</b-icon>
                    </b-navbar-item>
                </div>
            </b-navbar-item>
    </template>
</b-navbar>

    <section class="hero is-fullheight">
      <b-progress
        v-if="uploadPercent > 0"
        :type="
          uploadPercent > 80
            ? 'is-success'
            : uploadPercent > 60
            ? 'is-info'
            : uploadPercent > 40
            ? 'is-warning'
            : uploadPercent > 20
            ? ''
            : 'is-danger'
        "
        :value="uploadPercent"
        :rounded="false"
        style="height: 3px !important"
        show-value
        format="percent"
      ></b-progress>
      <section class="section">
        <router-view />
      </section>
    </section>

    <footer v-if="`${auth}`.length > 30" class="footer" style="padding: 8px !important">
      <div class="content has-text-centered">
        <p>
          <strong>ລະບົບເກັບກຳຂໍ້ມູນ ຄະນະບໍລິຫານງານ ສະຫະພັນກຳມະບານ ກະຊວງອຸດສະຫະກຳ ແລະ ການຄ້າ</strong>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "App",
  components: {},
  computed: mapState({
    auth: (state) => state.token,
    uploadPercent: (state) => state.uploadPercent,
    user: (state) => state.user,
  }),
  methods: {
    /* ...mapActions('cart', [
      'addProductToCart'
    ]),
  */
  },
  created() {
    this.$store.dispatch("updatePercent", 0);
  },
};
</script>


<style scoped>
   html * {
        font-family: 'Phetsarath OT','Noto Sans Southeast Asian','Saysettha OT', 'sans-serif';
      }
</style>
