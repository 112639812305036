import Vue from 'vue'

import HomeView from '../views/HomeView.vue'
import store from '../store' 

import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    meta:{
      requiresAuth:true,
      title:""
    },
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    meta:{
      requiresAuth:true,
      title:""
    },
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    meta:{
      requiresAuth:true,
      title:""
    },
    path: '/department',
    name: 'department',
    component: () => import('../views/DepartmentView.vue')
  },
  {
    meta:{
      requiresAuth:true,
      title:""
    },
    path: '/unit',
    name: 'unit',
    component: () => import('../views/UnitView.vue')
  },
  {
    meta:{
      requiresAuth:true,
      title:""
    },
    path: '/payment',
    name: 'payment',
    component: () => import('../views/PaymentView.vue')
  },
  {
    meta:{
      requiresAuth:true,
      title:""
    },
    path: '/paymentitem/:id',
    name: 'paymentitem',
    component: () => import('../views/PaymentView.vue')
  },
  {
    meta:{
      requiresAuth:true,
      title:""
    },
    path: '/person',
    name: 'person',
    component: () => import('../views/PersonalView.vue')
  },
  {
    meta:{
      requiresAuth:true,
      title:""
    },
    path: '/report',
    name: 'report',
    component: () => import('../views/ReportView.vue')
  },
  {
    meta:{
      requiresAuth:true,
      role:'admin',
      title:""
    },
    path: '/users',
    name: 'users',
    component: () => import('../views/UserView.vue')
  },
  {
    meta:{
      requiresAuth:true,
      title:""
    },
    path:'/changepwd',
    name:'changepwd-view',
    component: ()=> import('../views/ChangePwd.vue'),
  },
  {
    meta:{
      requiresAuth:false,
      title:""
    },
    path:'/login',
    name:'login',
    component: ()=> import('../views/LoginView.vue')
  },
  {
    meta:{
      requiresAuth:false,
      title:""
    },
    path:'/logout',
    name:'logout',
    component: ()=> import('../views/LogoutView.vue')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const authToken = `${store.state.token}`.length>30
  //console.log(`AuthToken: ${authToken}, meta: ${to.meta.requiresAuth}`);
  if (to.meta.requiresAuth===true && authToken===false && !to.path.startsWith('/login')) return next('/login')
  
  if(to.path.startsWith('/logout')){
    store.dispatch('setToken',{user:{},token:'',refreshToken:''})
  }
  if (authToken==true && to.path.startsWith('/login')) return next('/')

  return next()
})

export default router
